import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: '4rem',
      marginBottom: '4rem',
      '& h6': {
          marginTop: '1.76rem',
          //marginBottom: '3rem'
      }
    },
    contributor: {
        color: theme.palette.primary.dark,
        letterSpacing: '0.2rem'
    }
}));

function Header(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container maxWidth={false} className={classes.container}>
                <Container maxWidth="md">
                    <Typography variant="h5" gutterBottom>
                        FEATURED
                    </Typography>
                    <Typography variant="h2" component="h1" gutterBottom>
                        {props.header.title}
                    </Typography>
                    {/* <Typography variant="h5" component="h2" gutterBottom>
                        ... subheading who is your Role model?
                    </Typography> */}
                    <Typography variant="subtitle1" className={classes.contributor} gutterBottom>
                        By {props.header.author.fields.firstName} {props.header.author.fields.lastName}
                    </Typography>
                </Container>
            </Container>
        </div>
    )
}

export default Header;