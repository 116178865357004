import React, { useEffect, useRef } from 'react';
import { Editor, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      background: '#fff',
      borderStyle: 'solid',
      //border: '1px solid #000',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: '.5rem',
      borderWidth: '1px',
      fontSize: '14px',
      padding: '15px'
    },
    content: {
      margin: '0 -15px -15px',
      padding: '15px',
      minHeight: '100px'
    },
    editor: {
      borderTop: '1px solid rgba(0, 0, 0, 0.23)',
      cursor: 'text',
      fontSize: '16px',
      marginTop: '10px'
    },
    hidePlaceholder: {
      display: 'none'
    },
    controls: {
      fontFamily: '"Helvetica", sans-serif',
      fontSize: '14px',
      marginBottom: '5px',
      userSelect: 'none'
    },
    styledButton: {
      //color: 'rgba(0, 0, 0, 0.23)',
      color: 'rgba(0, 0, 0)',
      border: '1px solid #000',
      cursor: 'pointer',
      marginRight: '16px',
      padding: '2px',
      display: 'inline-block'
    },
    blockquote: {
      borderLeft: '5px solid #eee',
      color: '#666',
      fontFamily: '"Hoefler Text", "Georgia", serif',
      fontStyle: 'italic',
      margin: '16px 0',
      padding: '10px 20px'
    },
    activeButton: {
      color: '#5890ff'
    }
}));

function RichEditor(props) {
  const classes = useStyles();

  const { editorState } = props;

  const editor = useRef(null);
  const focusEditor = () => editor.current.focus();

  useEffect(() => {
    focusEditor()
  }, []);

  const handleKeyCommand = (command) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
          onChange(newState);
          return true;
      }
      return false;
  }

  const onTab = (e) => {
      const maxDepth = 4;
      onChange(RichUtils.onTab(e, editorState, maxDepth));
  }

  const toggleBlockType = (blockType) => {
    onChange(
          RichUtils.toggleBlockType(
              editorState,
              blockType
          )
      );
  }

  const toggleInlineStyle = (inlineStyle) => {
    onChange(
          RichUtils.toggleInlineStyle(
              editorState,
              inlineStyle
          )
      );
  }

    // const getBlockFunction = (block) => getBlockStyle(block, classes.blockquote);

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    var contentState = editorState.getCurrentContent();

    const onChange = (editorState) => {
      props.onChange('editorState', editorState);
    };

    return (
      <div className={classes.root}>
        <InlineStyleControls
          editorState={editorState}
          onToggle={toggleInlineStyle}
        />
        <BlockStyleControls
          editorState={editorState}
          onToggle={toggleBlockType}
        />
        {(!contentState.hasText() && contentState.getBlockMap().first().getType() !== 'unstyled') ?
          (<div className={`${classes.editor} ${classes.hidePlaceholder}`} onClick={focusEditor}>
            <Editor
              // blockStyleFn={getBlockFunction}
              customStyleMap={styleMap}
              editorState={editorState}
              handleKeyCommand={handleKeyCommand}
              onChange={onChange}
              onTab={onTab}
              placeholder="Tell a story..."
              ref={editor}
              spellCheck={true}
            />
          </div>) : (<div className={classes.editor} onClick={focusEditor}>
          <Editor
            // blockStyleFn={getBlockFunction}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            onChange={onChange}
            onTab={onTab}
            placeholder="Tell a story..."
            ref={editor}
            spellCheck={true}
          />
        </div>)}       
      </div>
    );

}

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

// function getBlockStyle(block, blockquote) {
//   switch (block.getType()) {
//     case 'blockquote': return blockquote; //'RichEditor-blockquote';
//     default: return null;
//   }
// }

function StyleButton(props) {
  const classes = useStyles();
    const onToggle = (e) => {
        e.preventDefault();
        props.onToggle(props.style);
    };

    return (
      <>
        {props.active ?
        (<span className={`${classes.styledButton} ${classes.activeButton}`} onMouseDown={onToggle}>
          {props.label}
        </span>) :
        (<span className={classes.styledButton} onMouseDown={onToggle}>
          {props.label}
        </span>)}
      </>
    );
}

const BLOCK_TYPES = [
  // {label: 'H1', style: 'header-one'},
  // {label: 'H2', style: 'header-two'},
  // {label: 'H3', style: 'header-three'},
  // {label: 'H4', style: 'header-four'},
  // {label: 'H5', style: 'header-five'},
  // {label: 'H6', style: 'header-six'},
  {label: 'Blockquote', style: 'blockquote'},
  {label: 'UL', style: 'unordered-list-item'},
  {label: 'OL', style: 'ordered-list-item'},
  {label: 'Code Block', style: 'code-block'},
];

function BlockStyleControls (props) {
  const classes = useStyles();
  const {editorState} = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className={classes.controls}>
      {BLOCK_TYPES.map((type) =>
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

var INLINE_STYLES = [
  {label: 'Bold', style: 'BOLD'},
  {label: 'Italic', style: 'ITALIC'},
  {label: 'Underline', style: 'UNDERLINE'},
];

function InlineStyleControls (props) {
  const classes = useStyles();
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className={classes.controls}>
      {INLINE_STYLES.map(type =>
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

export default RichEditor;