import React, { useRef, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import { makeStyles } from '@material-ui/core/styles';
import TwitterIcon from '@material-ui/icons/Twitter';
import EmailIcon from '@material-ui/icons/Email';
import Rellax from 'rellax';

const useStyles = makeStyles((theme) => ({
    root: {
      position: 'relative',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      flexGrow: 1,
    },
    container: {
        position: 'relative',
        top: '20vh',
        zIndex: 6,
        minHeight: '100vh', // TODO Change the height when small, use minHeight
    },

    icons: {
        '& > button': {
          margin: theme.spacing(2),
        },
      },
}));

function Contact(props) {
    const classes = useStyles();

    const rellaxRef = useRef(null);

    useEffect(() => {   
        new Rellax(rellaxRef.current, { // <---- Via useRef element
          speed: 2,
          center: false,
          wrapper: null,
          round: true,
          vertical: true,
          horizontal: false
        });
      }, []);  

    return (
        <div className={classes.root}>
            <Container maxWidth="md">
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center" className={`${classes.container}`} ref={rellaxRef}>
                    <Grid item>
                        <Typography variant="h2" component="h1" gutterBottom>Get in touch</Typography>
                    </Grid>     
                    <Grid item>
                        <Typography variant="h5" component="p" gutterBottom>Feel free to contact me</Typography>
                    </Grid>
                    <Grid item className={classes.icons}>
                        <IconButton color="secondary" aria-label="more stories">
                            <TwitterIcon fontSize="large"/>
                        </IconButton>
                        <IconButton color="secondary" aria-label="more stories">
                            <EmailIcon fontSize="large"/>
                        </IconButton>
                        {/* <IconButton color="secondary" aria-label="more stories">
                            <TwitterIcon fontSize="large"/>
                        </IconButton> */}
                    </Grid>
                </Grid>
             </Container>          
        </div>
    )
}

export default Contact;