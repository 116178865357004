import { ADD_COMMENT_PENDING, ADD_COMMENT_SUCCESS, ADD_COMMENT_FAILURE, FETCH_COMMENTS_SUCCESS } from '../constants';

export const initialState = {
    comments: [],
    comment: {
        name: '',
        text: ''
    },
    isCommenting: false,
    isCommented: false,
    error: null
}

export default function(state = initialState, action) {
    switch(action.type){        
        case ADD_COMMENT_SUCCESS:
            return {
                ...state,
                comments: [action.payload, ...state.comments],
                comment: {},
                isCommented: true,
                isCommenting: false
            }
 
        case ADD_COMMENT_PENDING:
            return {
                ...state,
                isCommenting: true,
                isCommented: false
            }

        case ADD_COMMENT_FAILURE:
            return {
                ...state,
                isCommenting: false,
                isCommented: false,
                error: action.error
            }

        case FETCH_COMMENTS_SUCCESS:
            return {
                ...state,
                comments: action.payload
            }
 
        case "RESET_COMMENT":
            return initialState

        default:
            return state
    }
}