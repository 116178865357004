import React from 'react';

import Navigation from './Navigation';
import Hero from './Hero';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
        //minHeight: '70vh',
        position: 'fixed', // really?
        top: '0',
        left: '0',
        right: '0'
        
    }
}));

function Header(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Navigation color="primary"/>
            <Hero click={props.click}/>
        </div>
    )
}

export default Header;