import React from 'react';
import { useContentful } from 'react-contentful';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import Header from './Header';
import ExpandButton from '../ExpandButton';

const useStyles = makeStyles((theme) => ({
    root: {
      position: 'relative',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      flexGrow: 1,
      //zIndex: 5,
      //minHeight: '100vh'
    },
    container: {
        position: 'relative',
        minHeight: '100vh', // TODO Change the height when small, use minHeight
        zIndex: 5,
    },
}));

function Story(props) {
    const classes = useStyles();

      // Get data from contentful
      // const [data, loading] = useContentfulData({ contentType: 'blogPost', 'fields.slug': props.id });
  
      const { data, fetched } = useContentful({
          contentType: 'featured',
          query: {
            'fields.slug': props.id,
            'select': 'fields.post'
          }
        });
  
      if(!fetched) return <div></div>;
      const post = data.items[0].fields.post.fields;

    return (
        <div className={classes.root}>
            <Container maxWidth="md">
                <Grid container alignItems="flex-end" justify="center" className={`${classes.container}`}>
                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="flex-start">
                            <Grid item>
                                <Header post={post} />
                            </Grid>
                            <Grid item>
                                <Typography variant="h6" component="p" gutterBottom>
                                    Many of us think of a role model as someone famous or iconic. But, anyone can inspire a child to achieve their potential in life. Growing up, my grandmother inspired me.

                                    My grandmother’s name is Sushila Singh. She lived with me and well, tolerated me for 18 years. Tonight I am going to talk about her 3 principles in life that make her my role model - Self-discipline, power of faith and being an empathetic listener.
                                </Typography>
                            </Grid>     
                        </Grid>
                    </Grid>
                    <Grid item>
                        <ExpandButton color="primary" click={props.click} />
                    </Grid>                        
                </Grid>
             </Container>          
        </div>
    )
}

export default Story;