import React from 'react';
import { useContentful } from 'react-contentful';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import Header from './Header';
import Body from './Body';
import Comment from '../Comment';
import Navigation from '../Navigation';


const useStyles = makeStyles((theme) => ({
    root: {
      padding: '4rem 0 2rem',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      '& div': {
            marginBottom: '4rem'
        }
    },
    container: {
        position: 'relative',
        zIndex: 5
    },
    body: {
        backgroundColor: theme.palette.primary.main,
        padding: '4rem 0'
    }
}));

function BlogContent(props) {
    const classes = useStyles();

    console.log('slug::', props.id);

    // Get data from contentful
    // const [data, loading] = useContentfulData({ contentType: 'blogPost', 'fields.slug': props.id });

    const { data, error, fetched, loading } = useContentful({
        contentType: 'blogPost',
        query: {
          'fields.slug': props.id,
        }
      });
      console.log('fetched', fetched);
      console.log('loading', loading);
      console.log('error', error); // TODO error then show 404 Not found page

    if(!fetched) return <div></div>;

    const post = data.items[0].fields;
    console.log('post:', post);

    return (
        <div className={classes.container}>
            <Navigation color="secondary"/>
            <div className={classes.root}>
                <Header header={post}/>
                <Container maxWidth="md">
                    <Typography variant="h6" component="p" gutterBottom>{post.introduction}</Typography>
                </Container>
            </div>
            <div className={classes.body}>
                <Body body={post.content}/> 
            </div>
            <Container maxWidth="md">
                <Comment />
            </Container>
                       
        </div>
    )
}

export default BlogContent;