import React, { useRef } from 'react';
import Container from '@material-ui/core/Container';

import Header from './Header';
import Contact from './Contact';
import Story from './Story/Story';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    // scroller
    scroller: {
        position: 'relative',
        top: '0'        
    },
}));

const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ 
        behavior: "smooth", 
        block: "nearest"
     });    
}   

function LandingPage(props) {
    const classes = useStyles();

    const storyRef = useRef(null);
    const contactRef = useRef(null);
    return (
        <div className={classes.scroller}>
 
            <Container maxWidth="md">
                <Header click={() => scrollToRef(storyRef)}/>
            </Container>

            <div ref={storyRef}>
                <Story click={() => scrollToRef(contactRef)}/>
            </div>
            
            <div ref={contactRef}>
                <Contact ref={contactRef} />
            </div>
           
        </div>
    )
}

export default LandingPage;