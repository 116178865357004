import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import Header from './Header';
import Body from './Body';
import Comment from '../Comment';

const useStyles = makeStyles((theme) => ({
    root: {
      padding: '4rem 0 2rem',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      '& div': {
            marginBottom: '4rem'
        }
    },
    container: {
        position: 'relative',
        zIndex: 5
    },
    body: {
        backgroundColor: theme.palette.primary.main,
        padding: '4rem 0'
    }
}));

function Blog(props) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.root}>
                <Header />
                <Container maxWidth="md">
                    <Typography variant="h6" component="p" gutterBottom>
                        Many of us think of a role model as someone famous or iconic. But, anyone can inspire a child to achieve their potential in life. Growing up, my grandmother inspired me.

                        My grandmother’s name is Sushila Singh. She lived with me and well, tolerated me for 18 years. Tonight I am going to talk about her 3 principles in life that make her my role model - Self-discipline, power of faith and being an empathetic listener.
                    </Typography>
                </Container>

            </div>
            <div className={classes.body}>
                <Body /> 
            </div>
            <Container maxWidth="md">
                <Comment />
            </Container>
                       
        </div>
    )
}

export default Blog;