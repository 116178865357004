/**
 * Copyright 2020, Dibya Singh, All rights reserved.
 */
import {
    addComment as addCommentAPI,
    getComments as getCommentsAPI,
  } from '../api/comments';
import { ADD_COMMENT_PENDING,
    ADD_COMMENT_SUCCESS,
    ADD_COMMENT_FAILURE,
    FETCH_COMMENTS_PENDING,
    FETCH_COMMENTS_SUCCESS,
    FETCH_COMMENTS_FAILURE } from '../constants';

export function addComment(blogId, comment) {
    return (dispatch, getState) => {
        dispatch({ type: ADD_COMMENT_PENDING });

        return addCommentAPI(blogId, comment)
            .then(res => {
                dispatch({ type: ADD_COMMENT_SUCCESS, payload: res.data })
            })
            .catch(err => {
                // Dispatch the generic "global errors" action
                // This is what makes its way into state.errors
                dispatch({type: ADD_COMMENT_FAILURE, error: err.message});
            });
    }
}

export function getComments(blogId) {
    return (dispatch, getState) => {
        dispatch({ type: FETCH_COMMENTS_PENDING });

        return getCommentsAPI(blogId)
            .then(res => {
                dispatch({ type: FETCH_COMMENTS_SUCCESS, payload: res.data })
            })
            .catch(err => {
                // Dispatch the generic "global errors" action
                // This is what makes its way into state.errors
                dispatch({type: FETCH_COMMENTS_FAILURE, error: err.message});
            });
    }
}