module.exports = {
    API_URL: process.env.REACT_APP_BASE_URL || 'http://localhost:3000/v1',
    CONTENTFUL: {
        CDN_URL: 'https://cdn.contentful.com/spaces'
    },
    SECRET: {
        CONTENTFUL: {
            DEFAULT_SPACE_NAME: process.env.REACT_APP_DEFAULT_SPACE_NAME || 'default',
            DEFAULT_ENVIRONMENT: process.env.REACT_APP_DEFAULT_ENVIRONMENT || 'master',
            default: {
                SPACE_ID: process.env.REACT_APP_SPACE_ID || 'y2jtsb9anqli',
                master: {
                    CDN_API_KEY: process.env.REACT_APP_CDN_API_KEY || 'ApaYFax9Mrqj-JDVacLHjiqo5ytkips0A6KYuz9Y7GM'
                }
            }
        }
    }
}