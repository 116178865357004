import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    expand: {
        marginBottom: '1rem',
        textAlign: 'center',
    }
}));

function ExpandButton(props) {
    const classes = useStyles();

    const onClick = () => {
        props.click();
    }

    return (
        <div className={classes.expand}>
            <IconButton color={props.color} aria-label="more stories" onClick={onClick}>
                <ExpandMoreRoundedIcon />
            </IconButton>
        </div>
    )
}

export default ExpandButton;