/**
 * Copyright 2020, Dibya Singh, All rights reserved.
 */
import axios from 'axios';
import { API_URL } from '../config';

/**
 * Get comments
 *
 * @param {String} blogId blog id
 *
 * @returns {Array} comments
 */
export function getComments(blogId) {
  // Get comments
  return axios.get(`${API_URL}/posts/${blogId}/comments`)
    .then(resp => {
      return resp;
    });
}

/**
 * Add comment
 *
 * @param {String} blogId blog id
 *
 * @returns {Object} comment
 */
export function addComment(blogId, comment) {
  return axios.post(`${API_URL}/posts/${blogId}/comments`, {
    text: comment.text,
    createdBy: comment.name
  })
  .then(resp => {
    return resp;
  })
}