import React, { useRef, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Rellax from 'rellax';
import ExpandButton from './ExpandButton';


const useStyles = makeStyles((theme) => ({
    root: {
      margin: 'auto',
      minHeight: 'calc(100vh - 8rem)',
      textAlign: 'center'
      //maxWidth: theme.breakpoints.values.sm,
    }
}));

function Hero(props) {
    const classes = useStyles();
    const rellaxRef = useRef(null);

    useEffect(() => {   
        new Rellax(rellaxRef.current, { // <---- Via useRef element
          speed: 6,
          center: false,
          wrapper: null,
          round: true,
          vertical: true,
          horizontal: false
        });
      }, []);    

    return (
        <div ref={rellaxRef}>
            <Grid container justify="center" alignItems="center">
                <Grid item>
                    <Grid className={classes.root} container direction="column" justify="center" alignItems="center">
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h4" component="h1" align="center">
                                Update 2 - Home to share thought provoking questions and stories. After all, sharing is caring.
                            </Typography>
                        </Grid>                      
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <ExpandButton click={() => props.click(true)} />
                </Grid>
            </Grid>

        </div>
    )
}

export default Hero;
