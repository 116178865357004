import React from 'react';
import { MuiThemeProvider, createMuiTheme, StylesProvider } from '@material-ui/core/styles';

import './App.css';

const theme = createMuiTheme({
   palette: {
    primary: {
      contrastText: '#000',
      dark: '#cccccc',
      light: "#fff",
      main: "#fff"
    },
    secondary: {
      contrastText: '#fff',
      dark: '#000',
      light: "#2c2c2c",
      main: "#000"
    },
   },
   typography: {
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(',')
   },
   overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none'
      }
    }
   }
});

export function renderApp(content) {
  return (
    <App>
      {content}
    </App>
  )
}

function App(props) {
  return (
    <MuiThemeProvider theme = { theme }>
      <StylesProvider injectFirst>     
        {props.children}
      </StylesProvider>
    </MuiThemeProvider>
  );
}

export default App;
