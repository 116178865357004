import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: '4rem',
      marginBottom: '4rem',
      '& h6': {
          marginTop: '1.76rem',
      }
    },
    contributor: {
        color: theme.palette.primary.dark,
        letterSpacing: '0.2rem'
    }
}));

function Header(props) {
    const classes = useStyles();
    const { post } = props;

    return (
        <div className={classes.root}>
            {/* <Container maxWidth="false" className={classes.container}> */}
                {/* <Container maxWidth="md"> */}
                    <Typography variant="h5" gutterBottom>
                        FEATURED
                    </Typography>
                    <Typography variant="h2" component="h1" gutterBottom>
                    <Link href={`/posts/${post.slug}`}>
                        {post.title}
                    </Link>                        
                    </Typography>
                    {/* <Typography variant="h5" component="h2" gutterBottom>
                        ... subheading who is your Role model?
                    </Typography> */}
                    <Typography variant="subtitle1" className={classes.contributor} gutterBottom>
                        By {post.author.fields.firstName} {post.author.fields.lastName}
                    </Typography>
                {/* </Container> */}
            {/* </Container> */}
        </div>
    )
}

export default Header;