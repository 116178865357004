import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';

import Logo from './Logo';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    }
}));

function Navigation(props) {
    const classes = useStyles();

    return (
        <AppBar color={props.color} position="static">
            <Toolbar>
                <Link href="/" color="inherit" className={classes.root} underline="none"><Logo /></Link>
                {/* <Link href="#" color="inherit">Stories</Link> */}
            </Toolbar>
        </AppBar>
    )
}

export default Navigation;