/**
 * Copyright 2020, Dibya Singh, All rights reserved.
 */
import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";
import { ContentfulClient, ContentfulProvider } from 'react-contentful';
import LandingPage from './components/LandingPage';
import Comment from './components/Comment';
import Blog from './components/Blog/Blog';
import BlogContent from './components/Blog/BlogContent';

import { SECRET} from './config'
import { renderApp } from './App';

const contentfulClient = new ContentfulClient({
    accessToken: SECRET.CONTENTFUL[SECRET.CONTENTFUL.DEFAULT_SPACE_NAME][SECRET.CONTENTFUL.DEFAULT_ENVIRONMENT].CDN_API_KEY,
    space: SECRET.CONTENTFUL[SECRET.CONTENTFUL.DEFAULT_SPACE_NAME].SPACE_ID,
});  

function Routes(props) {
    return (
        <ContentfulProvider client={contentfulClient}>
            <Router>
                <Switch>
                    <Route exact path="/" render={() => renderApp(
                    <div className="App"><LandingPage /></div>)} />
                    <Route path="/comments" render={() => renderApp(<Comment />)} />
                    <Route path="/blogs" render={() => renderApp(<Blog />)} />
                    {/* <Route path="/content" render={() => renderApp(<BlogContent />)} /> */}
                    <Route path="/posts/:id" render={({ match }) => renderApp(<BlogContent id={match.params.id} {...props} />)} />
                </Switch>
            </Router>
        </ContentfulProvider>
    );
}

export default Routes;
