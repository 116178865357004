// Add comment
export const ADD_COMMENT_PENDING = "ADD_COMMENT_PENDING";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const ADD_COMMENT_FAILURE = "ADD_COMMENT_FAILURE";

// Fetch comments
export const FETCH_COMMENTS_PENDING = "FETCH_COMMENTS_PENDING";
export const FETCH_COMMENTS_SUCCESS = "FETCH_COMMENTS_SUCCESS";
export const FETCH_COMMENTS_FAILURE = "FETCH_COMMENTS_FAILURE";

