import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        '& p': {
            marginBottom: '4rem'
        }
    }
}));

function Body(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container maxWidth="md">
                {/* <Typography variant="body1" gutterBottom> */}
                    {documentToReactComponents(props.body)}
                {/* </Typography> */}
            </Container>
        </div>

    )
}

export default Body;