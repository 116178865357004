import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { EditorState, convertToRaw } from 'draft-js';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import RichEditor from './Editor/RichEditor';
import CommentsList from './CommentsList';
import { addComment } from '../actions/commentActions';

const useStyles = makeStyles((theme) => ({
    root: {
      background: '#fff',
      fontFamily: '"Georgia, serif',
      fontSize: '14px',
      padding: '15px'      
    },
    form: {
        padding: '1rem 0',
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.23)'
            },
            '&.Mui-focused fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderWidth: 1,
            },
          },
    }
}));

function Comment(props) {
    const classes = useStyles();

    const [isAddingComment, setIsAddingComment] = useState(false);

    // Pass the useFormik() hook initial form values and a submit function that will
    // be called when the form is submitted
    const formik = useFormik({
        initialValues: {
            name: '',
            editorState: new EditorState.createEmpty(),
        },
        onSubmit: (values, { resetForm }) => {
            const contentState = values.editorState.getCurrentContent();
            props.addComment('the-blog', {
                name: values.name,
                text: JSON.stringify(convertToRaw(contentState))
            });

            resetForm({});
        },
    });

    return (
        <div className={classes.root}>
            <Link href="#"
                color="secondary"
                underline="always"
                onClick={(e) => { e.preventDefault(); setIsAddingComment(true); }}>ADD NEW COMMENT</Link>
            { isAddingComment &&
                <form onSubmit={formik.handleSubmit} className={classes.form}>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <TextField
                                id="name"
                                className={classes.border}
                                name="name"
                                label="Name"
                                variant="outlined"
                                color="secondary"
                                onChange={formik.handleChange}
                                value={formik.values.name} />
                        </Grid>
                        <Grid item>
                            <RichEditor id="text" name="editor"
                                editorState={formik.values.editorState}
                                onChange={formik.setFieldValue}></RichEditor>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" spacing={2}>
                                <Grid item>
                                    <Button variant="contained" color="secondary" type="submit">Add Comment</Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        size="medium"
                                        disableElevation 
                                        onClick={() => setIsAddingComment(false)}>Cancel</Button>
                                </Grid> 
                            </Grid>
                        </Grid>
                    </Grid>
                </form> }
            <CommentsList />
        </div>
    )
}

const mapStateToProps = ({ comments }) => ({
    error: comments.error,
});

const mapDispatchToProps = {
    addComment
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Comment));