import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      //margin: 'auto',
      fontFamily: 'MuseoModerno',
      // fontSize: '512px'
    }
}));

function Logo(props) {
    const classes = useStyles();

    return (
        <Typography className={classes.root} variant="h6">Dibya</Typography>
    )
}

export default Logo;