import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { EditorState, Editor, convertFromRaw } from 'draft-js';
import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import { getComments } from '../actions/commentActions';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      margin: '2rem 0',
      backgroundColor: 'rgba(241,243,244,1)'//theme.palette.background.paper,
    },
    inline: {
      display: 'inline',
    },
  }));

function CommentsList(props) {
    const classes = useStyles();

    const { getComments } = props;

    useEffect(() => {
        getComments('the-blog');
    }, [getComments]);

    return (
        <div className={classes.root}>
            <Container maxWidth="md">
                <List className={classes.root}>
                    {props.comments.map(comment => {
                        return (
                            <div>
                                <ListItem alignItems="flex-start">
                                    {/* <ListItemAvatar>
                                        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                                    </ListItemAvatar> */}
                                    <ListItemText
                                        primary={comment.createdBy}
                                        secondary={
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                <Editor
                                                editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(comment.text)))}
                                                readOnly={true}
                                                />
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component="li" />
                            </div>
                        )}
                    )}
                </List>
            </Container>

        </div>

    )
}

const mapStateToProps = ({ comments }) => ({
    comments: comments.comments,
});

const mapDispatchToProps = {
    getComments
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommentsList));